.HeaderPage {
    display: flex;
    flex-direction: column;
}

.divButtonContactsGroup {
    /* margin-top: 20px; */
    display: flex;
    justify-content: space-around;
    margin-left: 200px;
    text-align: center;
    background-color: #1976d2;
}
