#messageSms {
    margin-top: -25px;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
}

#formSms {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vw;
    margin-top: 40px;
}