.HeaderPage {
    display: flex;
    flex-direction: column;
}

.StatisticTab {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
}

#messageStat {
    margin-top: -25px;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
}