#messageContact {
    margin-top: -25px;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
}

.seachDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #ccc;
    border-radius: 4px; */
}
.buttonSearch{
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}