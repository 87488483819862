.HeaderPage {
    display: flex;
    flex-direction: column;
}

.SendingForm {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
    width: 80%;
}