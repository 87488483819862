.formBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 90vw; */
    margin-left: 10px
}

#formSearch {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.dateBlock {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.formBlockDate {
    display: flex;
    /* width: 90vw; */
    justify-content: flex-start;
}

.formUserSelect {
    display: flex;
    flex-direction: column;
    margin: 10px;
}